.hyperlink{
    color: #20a8d8;
}
.hyperlink:hover{
    text-decoration: underline; 
    cursor: pointer;
}


td:focus {
    outline:0;
}
tr:hover{
    cursor: pointer;
}
.table thead th {
vertical-align: middle;
border-bottom:0;
}

.table {
margin-bottom: 0;
}

.react-bs-table-no-data{
    text-align: center;
}

.saveAddress{
    color: #20a8d8;
    float: right;
    font-size: 80%;
}
.saveAddress:hover{
    cursor: pointer;
    color: #1694be;
}

.savedAddress{
    color: #4dbd74;
    float: right;
    font-size: 80%;
}

.fullcenter {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

.btnLink:hover{
    cursor: pointer;
    text-decoration: underline;
}

.btnLink{
    color: #1694be
}

@media (max-width: 991.98px){
.app-header .navbar-brand >img{
    display: none;
}

.sdImg{
    display:none;
}
}